ion-menu {
    --border: none;

    &::part(container), ion-list {
        background: var(--ion-color-base);
    }

    ion-item {
        --padding-top: 0;
        --padding-bottom: 0;
    }

    ion-accordion [slot='content'] ion-item {
        --ion-color-base: var(--ion-color-shade) !important;
    }
}
