swiper {
    --swiper-theme-color: var(--ion-color-base);
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-inactive-color: var(--ion-color-medium);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bottom: -32px;

    &:not(.ion-color) {
        --swiper-theme-color: var(--ion-color-primary);
    }

    // Use secondary color when shown within content with primary background
    ion-content.ion-color-primary &:not(.ion-color) {
        --swiper-theme-color: var(--ion-color-tertiary);
    }

    // Fix for Swiper's broken 'slidesPerView: auto' implementation
    &[slidesPerView='auto'] {
        .swiper-slide {
            width: auto;
        }
    }

    // Reset padding values that were removed by Swiper's CSS
    &.ion-padding {
        padding: var(--padding, 16px);
    }

    // Pagination styles
    .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: var(--swiper-pagination-bottom);
    }

    @media(prefers-reduced-motion: reduce) {
        .swiper-wrapper {
            transition-duration: 0s !important;
        }
    }

}
