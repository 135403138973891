ion-modal {

    ion-back-button {
        display: block;
    }

    &.action-sheet-modal {
        &.md {
            --border-radius: 8px 8px 0 0;
        }

        ion-header {
            --ion-safe-area-top: 0;
        }

        ion-toolbar.md {
            padding-bottom: 0;
        }

        ion-title:not(ion-buttons + ion-title) {
            text-align: start;
            padding-inline: 16px;
        }
    }

    @media (min-width: 960px) and (min-height: 720px) {
        --width: 800px;
        --height: 600px;
    }

}
