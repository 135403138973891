// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #13577A;
    --ion-color-primary-rgb: 19, 87, 122;
    --ion-color-primary-contrast: #FFFFFF;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #114D6B;
    --ion-color-primary-tint: #2B6887;

    --ion-color-secondary: #1CAAB6;
    --ion-color-secondary-contrast: #FFFFFF;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #1996A0;
    --ion-color-secondary-tint: #33B3BD;

    --ion-color-tertiary: #E3F4F5;
    --ion-color-tertiary-rgb: 227, 244, 245;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #C8D7D8;
    --ion-color-tertiary-tint: #F3F5F8;

    --ion-color-success: #2DD36F;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28BA62;
    --ion-color-success-tint: #42D77D;

    --ion-color-warning: #FFC409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #E0AC08;
    --ion-color-warning-tint: #FFCA22;

    --ion-color-danger: #EA7979;
    --ion-color-danger-rgb: 234, 121, 121;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #CE6A6A;
    --ion-color-danger-tint: #EC8686;

    --ion-color-light: #F3F5F8;
    --ion-color-light-rgb: 243, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #D6D8DA;
    --ion-color-light-tint: #F4F6F9;

    --ion-color-medium: #BFC8D9;
    --ion-color-medium-rgb: 191, 200, 217;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #737A88;
    --ion-color-medium-tint: #C5CEDD;

    --ion-color-dark: #11161F;
    --ion-color-dark-rgb: 17, 22, 31;
    --ion-color-dark-contrast: #FFFFFF;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #01060F;
    --ion-color-dark-tint: #2E3342;

    --ion-font-family: 'Inter', sans-serif;
    --ion-text-color: #11161F;

    // Custom
    --ion-body-background: #FFFFFF;
    --ion-text-muted-color: var(--ion-color-medium-shade);

    --ion-box-shadow: 0 2px 4px 0 rgba(var(--ion-color-secondary-rgb), .08);
    --ion-box-shadow-large: 0 2px 8px 0 rgba(var(--ion-color-secondary-rgb), .08);
    --ion-box-shadow-inset: inset 0 2px 4px 0 rgba(var(--ion-color-secondary-rgb), .08);

    --ion-font-weight-normal: 400;
    --ion-font-weight-semibold: 500;
    --ion-font-weight-bold: 600;

    --ion-font-size-1: 0.75rem;
    --ion-font-size-2: 0.875rem;
    --ion-font-size-3: 1rem;
    --ion-font-size-4: 1.25rem;
    --ion-font-size-5: 1.5rem;
    --ion-font-size-6: 2rem;

    --ion-animation-duration-multiplier: 1;

    @media(prefers-reduced-motion: reduce) {
        --ion-animation-duration-multiplier: 0;
    }

    @media(prefers-contrast: less) {
        --ion-color-primary: #2B6887;
        --ion-color-primary-rgb: 43, 104, 135;
    }

    @media(prefers-contrast: more) {
        --ion-color-primary: #053E5B;
        --ion-color-primary-rgb: 5, 62, 91;
    }

    @media(prefers-contrast: custom) {
        --ion-color-primary: #222222;
        --ion-color-primary-rgb: 34, 34, 24;
        --ion-color-primary-shade: #000000;
        --ion-color-primary-tint: #CCCCCC;
        --ion-text-color: #000000;
        --ion-text-muted-color: #888888;
    }

    @media(forced-colors: active) {
        --ion-color-primary: #222222;
        --ion-color-primary-rgb: 34, 34, 24;
        --ion-color-primary-shade: #000000;
        --ion-color-primary-tint: #CCCCCC;
        --ion-color-medium: #444444;
        --ion-color-medium-rgb: 68, 68, 68;
        --swiper-pagination-color: var(--ion-color-secondary);

        .ion-text-muted {
            color: #FFFFFF !important;
        }

        * {
            forced-color-adjust: none;
        }
    }
}

html {
    /*
     * For more information on dynamic font scaling, visit the documentation:
     * https://ionicframework.com/docs/layout/dynamic-font-scaling
     */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
}
