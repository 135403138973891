ion-toolbar {

    &.button-toolbar {
        --min-height: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        border: 1px solid var(--ion-color-light);
        border-radius: 4px;
        box-shadow: var(--ion-box-shadow);

        ion-buttons {
            padding: 0;
        }

        > ion-buttons {
            min-height: 0;

            ion-button {
                --box-shadow: none;
                --padding-start: 4px;
                --padding-end: 4px;
                margin: 0;
                flex-grow: 1;

                + ion-button {
                    border-left: 1px solid var(--ion-color-light);
                }
            }
        }
    }

    &.toolbar-statusbar-only {
        display: none;

        html.plt-hybrid & {
            display: block;
            --min-height: 0;
            //--border-width: 0 !important;
        }
    }

    .header-translucent & {
        --opacity: 0;
        --background: transparent;
        --ion-color-base: transparent !important;
    }

}
