$paddingMarginSizes: (
    -6: -64px,
    -5: -48px,
    -4: -24px,
    -3: -12px,
    -2: -8px,
    -1: -4px,
    0: 0,
    1: 4px,
    2: 8px,
    3: 12px,
    4: 24px,
    5: 48px,
    6: 64px,
    auto: auto,
);

$locations: (
    top,
    left,
    right,
    bottom,
);

$properties: (
    padding,
    margin,
);

@each $paddingMarginSize, $size in $paddingMarginSizes {
    @each $location in $locations {
        @each $property in $properties {
            .ion-#{$property}-#{$location}-#{$paddingMarginSize} {
                #{$property}-#{$location}: $size !important;
            }

            .ion-#{$property}-#{$location} {
                #{$property}-#{$location}: var(--ion-padding) !important;
            }
        }
    }

    @each $property in $properties {
        .ion-#{$property}-horizontal-#{$paddingMarginSize} {
            #{$property}-left: $size !important;
            #{$property}-right: $size !important;
        }

        .ion-#{$property}-vertical-#{$paddingMarginSize} {
            #{$property}-top: $size !important;
            #{$property}-bottom: $size !important;
        }

        .ion-#{$property}-#{$paddingMarginSize} {
            #{$property}-top: $size !important;
            #{$property}-right: $size !important;
            #{$property}-bottom: $size !important;
            #{$property}-left: $size !important;
        }
    }
}

.ion-w-100 { width: 100% !important; }

.ion-min-w-100 { min-width: 100% !important; }

.ion-max-w-100 { max-width: 100% !important; }

.ion-h-100 { height: 100% !important; }

.ion-min-h-100 { min-height: 100% !important; }

.ion-max-h-100 { max-height: 100% !important; }

.ion-semi-hidden {
    position: fixed;
    pointer-events: none;
    visibility: hidden;
}

.plt-desktop .ion-hide-on-desktop {
    display: none !important;
}

.plt-mobile .ion-hide-on-mobile {
    display: none !important;
}

.ion-no-margin-inline {
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.ion-no-inner-padding {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
}

.ion-flex {
    display: flex;
    flex-direction: column;
}

.ion-flex-column {
    flex-direction: column;
}

.ion-flex-row {
    flex-direction: row;
}

.ion-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ion-flex-grow {
    flex-shrink: 0;
    flex-grow: 1;
}

.ion-flex-shrink {
    flex-shrink: 1;
    flex-grow: 0;
}

.ion-overflow-visible {
    overflow: visible;
}

.ion-user-select-text {
    user-select: text;
}

.ion-pointer-events-none {
    pointer-events: none;
}

.ion-keep-white-space {
    white-space: pre-wrap;
}

.ion-shadow {
    box-shadow: var(--app-box-shadow);
}

.ion-text-color {
    color: var(--ion-color-base);
}

.ion-item-reset {
    --background: transparent;
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --inner-padding-top: 0;
    --inner-padding-end: 0;
    --inner-padding-bottom: 0;
    --inner-padding-start: 0;
    --padding-start: 0;
    --padding-end: 0;
    --min-height: 0;
}

.error-text {
    font-size: 14px;
    color: var(--ion-color-danger);
}

[hidden], .hidden {
    display: none !important; // This is somehow needed to fix issues with <ion-spinner> (in)visibility
}

[appExternalContent] {
    img {
        max-width: 100%;
        height: auto;
    }

    a * {
        pointer-events: none;
    }

    table {
        th {
            font-weight: var(--ion-font-weight-bold);
        }
        td {
            border: 1px solid var(--ion-color-medium);
            padding: 8px;
        }
    }

    iframe[src*="youtube.com"],
    iframe[src*="youtu.be"],
    iframe[src*="youtube-nocookie.com"],
    iframe[src*="vimeo.com"]
    {
        max-width: 100%;
        height: auto;
        aspect-ratio: 16/9;
    }
}

// Prevent any type of button or other element from showing ugly default browser highlights/outlines
* {
    -webkit-tap-highlight-color: transparent;
    outline: none;
}
