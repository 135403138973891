ion-item {
    --border-color: var(--ion-color-light);
    --inner-padding-top: 8px;
    --inner-padding-bottom: 8px;
    --detail-icon-opacity: 1;
    --detail-icon-font-size: var(--ion-font-size-3);
    font-size: var(--ion-font-size-3);

    &.ion-no-inner-padding {
        --padding-start: 0;
        --padding-end: 0;
    }

    // Remove lines by default
    &:not([lines]) {
        --inner-border-width: 0;
        border: none;
    }

    &[fill='clear'] {
        @extend .ion-item-reset;
        width: fit-content;

        ion-checkbox, ion-radio {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    // Hide bottom border on last item in list
    &:last-of-type:not(.ion-item-checkbox,.ion-item-radio)::part(native) {
        border-bottom-width: 0;
    }

    &.has-checkbox, &.has-radio {
        border: 2px solid var(--ion-color-light);
        border-radius: 6px;

        &:has(.checkbox-checked),
        &:has(.radio-checked) {
            --background: var(--ion-color-primary-tint);
            border-color: var(--ion-color-primary);
        }
    }

    // Reset some typography
    &:not(.ion-color) {
        --color: var(--ion-text-color);
    }

    .sc-ion-label-md-s,
    .sc-ion-label-ios-s {
        @extend .ion-reset-font;

        &.ion-text-wrap {
            font-size: var(--ion-font-size-3);
        }

        > p:not(:first-child) {
            font-size: var(--ion-font-size-2);
        }
    }

    // List item swiper
    .item-swiper & {
        max-width: calc(100% - 17px);
    }

    ion-list.ion-flex-row & {
        &:not(:last-of-type) {
            border-right: 1px solid var(--ion-color-medium);
        }
    }

    // Indicators
    .indicator {
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 0;
        width: 4px;
        background: var(--ion-color-tertiary);
        border-radius: 0 4px 4px 0 / 0 8px 8px 0;
    }

    &.ion-color .indicator {
        background: var(--ion-color-contrast);
    }

    // Avatar size and position
    ion-avatar[slot='start'] {
        margin: 0;
        margin-inline: 0 16px;
    }

    // Custom detail button
    .item-detail-button {
        --padding-start: 4px;
        --padding-end: 4px;
        min-width: 40px;
        box-shadow: var(--ion-box-shadow);
        pointer-events: none;

        ion-icon {
            font-size: var(--ion-font-size-3);
        }
    }

    // Smaller 'more options' button
    > ion-button[slot='end']:not([size]) {
        --padding-start: 12px;
        --padding-end: 12px;
    }

}
