ion-footer {
    opacity: 1;
    transform: scale(1) translateY(0);
    transform-origin: center bottom;
    transition: opacity calc(.2s * var(--ion-animation-duration-multiplier)) ease-out,
    transform calc(.2s * var(--ion-animation-duration-multiplier)) ease-out;
    will-change: opacity, transform;

    &.footer-translucent {
        box-shadow: none;

        .footer-background {
            display: none;
        }

        ion-toolbar {
            --opacity: 1;
            --background: transparent;
            --border-width: 0 !important;
        }
    }

    &.hidden {
        opacity: 0;
        transform: scale(0) translateY(calc(100% + 32px));
        pointer-events: none;
    }

}
