ion-input {

    // 'Fix' the poor default outline styling to make it consistent on iOS and Android
    &[fill='outline'] {
        --background: var(--ion-body-background);
        --border-color: var(--ion-color-medium);
        --placeholder-color: var(--ion-color-medium);
        --placeholder-opacity: 1;
        --padding-top: 4px;
        --padding-bottom: 4px;
        --padding-start: 16px !important;
        --padding-end: 16px;
        --border-radius: 5px !important;
        min-height: 40px !important;
        border: none;
        border-radius: var(--border-radius);

        // Move label outside the input container
        &[labelPlacement='stacked'],
        &[label-placement='stacked'] {
            margin-top: max(1.25rem, 26px);

            // Remove the default label styling
            .label-text-wrapper {
                transform: none !important;
                max-width: none !important;
                position: absolute;
                top: calc(-1 * max(1.25rem, 26px));
                left: 0;
                font-size: var(--ion-font-size-2);
                font-weight: var(--ion-font-weight-bold);
            }

            // Move 'remaining characters counter' to the top
            .counter {
                position: absolute;
                top: -22px;
                right: 0;
            }
        }

        // Hide unnecessary outline container on Android
        .input-outline-container {
            display: none !important;
        }

        // Add the border to a more appropriate element
        .input-wrapper {
            border: 1px solid var(--border-color) !important;
            box-shadow: var(--ion-box-shadow-inset);
        }

        // Remove extra margin
        .native-input {
            margin: 0 !important;
        }

        // Error and help texts don't need a spacing on the left side
        .input-bottom {
            --padding-start: 0;
            border: none;
            justify-content: flex-start;

            .error-text {
                font-size: var(--ion-font-size-2);
            }
        }

    }

    // Use default color for labels with errors
    &.ion-touched.ion-invalid.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
        &, .label-text {
            color: inherit !important;
        }
    }

    ion-button.button-has-icon-only {
        margin-block: -6px;
    }

}
