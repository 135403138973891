ion-content:not(ion-modal ion-content):not(ion-popover ion-content) {

    // For pages without a header, add padding to the top of the content OR the first <header> element if there is one
    &:not(ion-header + &) {
        --padding-top: var(--ion-safe-area-top);

        > header:first-child {
            margin-top: calc(var(--ion-safe-area-top) * -1);
            padding-top: calc(var(--ion-safe-area-top) + var(--padding-top));
        }
    }

}
