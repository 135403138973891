// Cordova only:
// When the software keyboard is open, add padding to the bottom of the current page to match the keyboard height.
// This fixes issues where form inputs 'behind' the keyboard could not scroll into view
html.plt-android.keyboard-visible {
    .ion-page:not(ion-app):not(app-tabs):not(.ion-delegate-host):not(.ion-page-hidden) {
        padding-bottom: calc(
            var(--keyboard-height)
            + var(--ion-safe-area-top)
            + var(--ion-safe-area-bottom)
        );
    }
}

html.qr-scanner-visible {
    .ion-page:not(ion-app):not(app-tabs):not(.ion-delegate-host):not(.ion-page-hidden) {
        .content-card {
            --background: transparent;
        }

        ion-content {
            --background: transparent;
            --ion-color-base: transparent !important;
        }
    }
}

// Hide menu button when back button is visible
.ion-page.can-go-back ion-back-button + ion-menu-button {
    display: none;
}
